<script setup lang="ts">
import { AppRedirect } from '@/widgets/app-redirect'
import { FooterTabBar } from '@/widgets/footer-tab-bar'
import { Preloader } from '@/widgets/preloader'

import { ROUTE_NAV } from '../config'

// useHead({
//   meta: [
//     {
//       name: 'theme-color',
//       content: '#F2F2F6',
//     },
//   ],
// })

const route = useRoute()
const localePath = useLocalePath()
const isStoriesPage = computed(() => localePath(route.path) === localePath(ROUTE_NAV.cabStories))
</script>

<template>
  <div>
    <Preloader />
    <client-only>
      <div class="min-h-svh flex flex-col" :class="isStoriesPage ? 'bg-gray-900' : 'bg-gray-200'">
        <AppRedirect />
        <div class="overflow-hidden w-full flex-grow max-w-640 flex flex-col mx-auto">
          <slot />
        </div>
        <FooterTabBar />
      </div>
    </client-only>
  </div>
</template>
