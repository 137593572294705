<script setup lang="ts">
import { ROUTE_NAV } from '@/shared/config'

import { chatEntity } from '@/entities/chat'
import { productTour } from '@/entities/product-tour'
import { videoCallEntity } from '@/entities/video-call'

import IconTabBarAveolaClub from './IconTabBarAveolaClub.svg'
import IconTabBarAveolaClubActive from './IconTabBarAveolaClubActive.svg'
import IconTabBarAveolaClubHover from './IconTabBarAveolaClubHover.svg'
import IconTabBarMessages from './IconTabBarMessages.svg'
import IconTabBarRewards from './IconTabBarRewards.svg'
import IconTabBarRewardsActive from './IconTabBarRewardsActive.svg'
import IconTabBarRewardsHover from './IconTabBarRewardsHover.svg'
import IconTabBarStories from './IconTabBarStories.svg'
import IconTabBarVideoChat from './IconTabBarVideoChat.svg'

const ATTR_TOP_PROFILES = 'nav-top-profiles'

// State: start ------------------------------------
const route = useRoute()
const localePath = useLocalePath()

const topProfilesTour = productTour.model.useProductTour(ATTR_TOP_PROFILES)
const videoCallStore = videoCallEntity.model.useVideoCallStore()

const isAveolaClubRedDotVisible = ref(true)
const showBg = computed(() => !activeStories.value && !activeVideoChat.value)
const isTopProfilesProductTourVisible = computed(() => topProfilesTour.isTourActive.value)
const activeMessages = computed(() =>
  localePath(ROUTE_NAV.cabChat).includes(localePath(route.path)),
)
const activeVideoChat = computed(() =>
  localePath(ROUTE_NAV.cabVideoChat).includes(localePath(route.path)),
)
const videoCallActive = computed(() => videoCallStore.isVideoCallScreenMounted)
const isChatThreadOpen = computed(
  () => chatEntity.model.useChatStore().threadSelectedType === 'dialog',
)
const activeAveolaClub = computed(
  () =>
    localePath(ROUTE_NAV.cabTopProfiles).includes(localePath(route.path)) ||
    isTopProfilesProductTourVisible.value,
)
const activeRewards = computed(() =>
  localePath(ROUTE_NAV.cabRewards).includes(localePath(route.path)),
)
const activeStories = computed(() =>
  localePath(ROUTE_NAV.cabStories).includes(localePath(route.path)),
)

function onTopProfiles() {
  isAveolaClubRedDotVisible.value = false
  sessionStorage.setItem('aveola-club-active', JSON.stringify(false))
}

// State: end ------------------------------------
// Hooks: start ------------------------------------

onMounted(() => {
  const sessionRedDotKey = sessionStorage.getItem('aveola-club-active')
  if (sessionRedDotKey) isAveolaClubRedDotVisible.value = JSON.parse(sessionRedDotKey)
})

// Hooks: end ------------------------------------
// Comp logic ------------------------------------
</script>

<template>
  <div
    v-if="!videoCallActive && !isChatThreadOpen"
    class="bottom-0 left-0 z-10 right-0 text-gray-700 absolute cursor-pointer select-none"
    :class="{ 'bg-gray-200': showBg, 'z-50': isTopProfilesProductTourVisible }"
  >
    <div class="flex justify-between pb-8 pt-6 mx-auto max-w-640">
      <NuxtLink
        tag="div"
        class="flex w-1/5 flex-col items-center justify-between group"
        :to="localePath(ROUTE_NAV.cabChat)"
      >
        <IconTabBarMessages
          class=""
          :class="{
            'fill-black': activeMessages,
            'group-hover:fill-gray-800 fill-gray-500': !activeMessages,
          }"
        />
        <div class="text-h8-semibold mt-4">
          {{ $t('footer_tab_bar.messages') }}
        </div>
      </NuxtLink>
      <NuxtLink
        tag="div"
        data-product-tour="nav-top-profiles-target"
        class="flex w-1/5 relative flex-col items-center justify-between group"
        :class="{
          'before:absolute before:left-1/2 before:translate-x-6 before:top-2 before:rounded-full before:w-8 before:h-8 before:bg-red':
            isAveolaClubRedDotVisible && !activeAveolaClub,
        }"
        :to="localePath(ROUTE_NAV.cabTopProfiles)"
        @click="onTopProfiles"
      >
        <IconTabBarAveolaClubActive v-if="activeAveolaClub" />
        <IconTabBarAveolaClubHover v-if="!activeAveolaClub" class="group-hover:block hidden" />
        <IconTabBarAveolaClub v-if="!activeAveolaClub" class="group-hover:hidden block" />
        <div class="text-h8-semibold mt-4">
          {{ $t('footer_tab_bar.aveola_club') }}
        </div>
      </NuxtLink>
      <NuxtLink
        tag="div"
        class="flex w-1/5 flex-col items-center justify-between group"
        :to="localePath(ROUTE_NAV.cabVideoChat)"
      >
        <IconTabBarVideoChat
          :class="{
            'fill-black': !activeVideoChat,
            'fill-white': activeVideoChat,
            'group-hover:fill-gray-800 fill-gray-500': !activeVideoChat,
          }"
        />
        <div class="text-h8-semibold mt-4">
          {{ $t('footer_tab_bar.video_chat') }}
        </div>
      </NuxtLink>
      <NuxtLink
        tag="div"
        class="flex w-1/5 flex-col items-center justify-between group"
        :to="localePath(ROUTE_NAV.cabRewards)"
      >
        <IconTabBarRewardsActive v-if="activeRewards" />
        <IconTabBarRewardsHover v-if="!activeRewards" class="group-hover:block hidden" />
        <IconTabBarRewards v-if="!activeRewards" class="group-hover:hidden block" />
        <div class="text-h8-semibold mt-4">
          {{ $t('footer_tab_bar.rewards') }}
        </div>
      </NuxtLink>
      <NuxtLink
        tag="div"
        class="flex w-1/5 flex-col items-center justify-between group"
        :to="localePath(ROUTE_NAV.cabStories)"
      >
        <IconTabBarStories
          :class="{
            'fill-white': activeStories,
            'group-hover:fill-gray-800 fill-gray-500': !activeStories,
          }"
        />
        <div class="text-h8-semibold mt-4">
          {{ $t('footer_tab_bar.stories') }}
        </div>
      </NuxtLink>
    </div>
  </div>
</template>
