<script setup lang="ts">
import { appEntity } from '@/entities/app'
import { appsflyerDeeplinkEntity } from '@/entities/appsflyer-deeplink'
import { videoCallEntity } from '@/entities/video-call'

import IconClose from './IconClose.vue'
import IconLogo from './IconLogo.vue'
import IconStarts from './IconStarts.vue'

const { $analytics } = useNuxtApp()

const ANALYTICS_TIMEOUT_DELAY_MS = 200
const SESSION_STORE_KEY = 'app-redirect-shown'

const deeplink = appsflyerDeeplinkEntity.model.useDeeplink()
const videoCallStore = videoCallEntity.model.useVideoCallStore()
const appStore = appEntity.model.useAppStore()

const showHeaderBar = computed(() => appStore.showHeaderBar && !videoCallStore.status)

onBeforeMount(() => {
  if (sessionStorage.getItem(SESSION_STORE_KEY) === 'true') {
    appStore.showHeaderBar = false
  }
})

function onOpen() {
  $analytics.event('redirect_button_pressed', {
    entry: 'top_native',
  })
  const timeout = setTimeout(() => {
    clearTimeout(timeout)
    deeplink.open()
  }, ANALYTICS_TIMEOUT_DELAY_MS)
}

function onClose() {
  appStore.showHeaderBar = false
  sessionStorage.setItem(SESSION_STORE_KEY, 'true')
}
</script>

<template>
  <div v-if="showHeaderBar" class="bg-white pb-6 pt-16 flex w-full max-w-640 mx-auto">
    <div
      class="px-8 h-inherit grow-0 shrink-0 flex items-center justify-center cursor-pointer"
      @click="onClose"
    >
      <IconClose />
    </div>
    <div class="h-inherit flex grow shrink justify-between items-center">
      <div class="flex grow items-center">
        <IconLogo class="mr-8" />
        <div class="text-p-semibold text-black">
          <div>{{ $t('app_redirect.title') }}</div>
          <div class="flex items-center text-h9-medium text-gray-800 leading-none">
            <IconStarts class="mr-4" />
            (16 597)
          </div>
        </div>
      </div>
      <div class="h-inherit flex items-center" @click="onOpen">
        <div
          class="w-96 h-28 mr-24 grow-0 shrink-0 cursor-pointer flex rounded-96 text-p-medium text-white justify-center items-center uppercase bg-[#007AFF]"
        >
          {{ $t('app_redirect.button') }}
        </div>
      </div>
    </div>
  </div>
</template>
